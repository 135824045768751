import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GoogleLoginButton from './GoogleLoginButton';
import { useAuth } from '../AuthContext';
import { CLIENT_ID, API_KEY } from '../config';
import { useNavigate, useLocation } from 'react-router-dom';

const ScheduleEvent = ({ courseId, handleCloseModal, setCourse, setSelectedChapter, setReload, reload }) => {
  const [summary, setSummary] = useState('Live Class');
  const [startDate, setStartDate] = useState('2024-09-20');
  const [startTime, setStartTime] = useState('09:00');
  const [duration, setDuration] = useState(60);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const location = useLocation();

  const calculateEndDateTime = (startDate, startTime, duration) => {
    const [hours, minutes] = startTime.split(':').map(Number);
    const start = new Date(`${startDate}T${startTime}`);
    const end = new Date(start.getTime() + duration * 60000);
    const endDate = end.toLocaleDateString('en-CA');
    const endTime = end.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    return { endDate, endTime };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setMessage('');
    setLoginError('');

    const { endDate, endTime } = calculateEndDateTime(startDate, startTime, duration);
    const offset = "+05:30";
    const formattedStartDateTime = `${startDate}T${startTime}:00${offset}`;
    const formattedEndDateTime = `${endDate}T${endTime}:00${offset}`;

    try {
      const token = sessionStorage.getItem('access_token');
      const response = await fetch('https://www.googleapis.com/calendar/v3/calendars/primary/events?conferenceDataVersion=1', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          summary,
          start: {
            dateTime: formattedStartDateTime,
            timeZone: 'Asia/Kolkata',
          },
          end: {
            dateTime: formattedEndDateTime,
            timeZone: 'Asia/Kolkata',
          },
          conferenceData: {
            createRequest: {
              requestId: 'sample123',
              conferenceSolutionKey: {
                type: 'hangoutsMeet',
              },
            },
          },
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response:', errorData);
        if (errorData.error.code === 401) {
          setLoginError('Your session has expired. Please log in again.');
          return; 
        }
        throw new Error(`Error ${response.status}: ${errorData.error.message}`);
      }

      const data2 = await response.json();
      let newChapter = {
        type: 'live_class',
        live_class: {
          title: summary,
          start_date: startDate,
          start_time: startTime,
          duration: duration.toString(),
          link: data2.hangoutLink,
        },
      };

      await axios.post(`https://backend.azad.deloai.com/admin/course/${courseId}/chapters`, newChapter);
      const updatedCourse = await axios.get(`https://backend.azad.deloai.com/admin/course/courses/${courseId}`);
      setCourse(updatedCourse.data);
      setSelectedChapter(updatedCourse.data.chapters[0]);
      handleCloseModal();
      alert('Chapter added successfully!');
      setMessage('Event successfully created!');
    } catch (error) {
      console.error('Error creating event:', error);
      setMessage(`Failed to create event: ${error.message}`);
    } finally {
      setReload(!reload);
      setIsLoading(false);
    }
  };


  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Title:
            <input
              type="text"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Start Time:
            <input
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Duration (minutes):
            <input
              type="number"
              value={duration}
              onChange={(e) => setDuration(Number(e.target.value))}
            />
          </label>
        </div>
        <button type="submit">Schedule Event</button>
      </form>
      {isLoading && <p>Scheduling your event...</p>}
      {message && <p>{message}</p>}
      {loginError && (
        <div>
          <p>{loginError}</p>
          <GoogleLoginButton redirectTo={location.pathname} /> {/* Pass current path */}
        </div>
      )}
      
    </div>
  );
};

export default ScheduleEvent;
