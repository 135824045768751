import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import './StudentDetail.css';
import HomeVerticalNavbar from '../../components/VerticalNavBars/HomePage';
import { useNavigate } from 'react-router-dom';

const StudentDetail = () => {
  const navigate = useNavigate();

  const { studentId } = useParams();
  const [student, setStudent] = useState(null);
  const [courses, setCourses] = useState([]);
  const [packages,setPackages] =useState([])
  const [reload, setReload] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [enrolledSearch, setEnrolledSearch] = useState('');
  const [enrolledPackageSearch, setEnrolledPackageSearch] = useState('');

  const [allCoursesSearch, setAllCoursesSearch] = useState('');
  const [allPackageSearch, setAllPackageSearch] = useState('');


  useEffect(() => {
    axios.get(`https://backend.azad.deloai.com/admin/student/students/${studentId}`)
      .then(response => {
        setStudent(response.data);
      })
      .catch(error => {
        setErrorMessage('There was an error fetching the student details!');
        setSuccessMessage('')
      });
  }, [studentId, reload]);

  useEffect(() => {
    axios.get('https://backend.azad.deloai.com/admin/course/courses',
      {
        params:{
          limit:10000,
          offset:0
        }
      }
    )
      .then(response => {
        setCourses(response.data);
      })
      .catch(error => {
        setErrorMessage('There was an error fetching the courses!');
        setSuccessMessage('')
      });
  }, []);
  useEffect(() => {
    axios.get('https://backend.azad.deloai.com/admin/package/packages',
      {
        params: {
          limit: 10000, // Large number to ensure all courses are fetched
          offset: 0
        }
      }
    )
      .then(response => {
        setPackages(response.data);
      })
      .catch(error => {
        setErrorMessage('There was an error fetching the courses!');
        setSuccessMessage('')
      });
  }, []);

  const handleResetIp = () => {
    axios.put(`https://backend.azad.deloai.com/admin/student/students/${student.id}/reset_ip`)
      .then(response => {
        setStudent(response.data);
        setSuccessMessage('IP address reset successfully!');
        setErrorMessage('')
      })
      .catch(error => {
        setErrorMessage('There was an error resetting the IP address!');
        setSuccessMessage('')
      });
  };

  const handleChangePassword = () => {
    const newPassword = prompt('Enter new password:');
    if (newPassword) {
      axios.put(`https://backend.azad.deloai.com/admin/student/students/${student.id}/change_password`, { new_password: newPassword })
        .then(response => {
          setStudent(response.data);
          setSuccessMessage('Password changed successfully!');
          setErrorMessage('')
        })
        .catch(error => {
          setErrorMessage('There was an error changing the password!');
          setSuccessMessage('')
        });
    }
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this student?')) {
      axios.delete(`https://backend.azad.deloai.com/admin/student/students/${student.id}`)
        .then(() => {
          setStudent(null);
          setSuccessMessage('Student deleted successfully!');
          alert('Student deleted successfully!')
          setErrorMessage('')
          navigate('/'); // Redirect to the homepage after successful deletion

        })
        .catch(error => {
          setErrorMessage('There was an error deleting the student!');
          setSuccessMessage('')
        });
    }
  };

  const handleUnenroll = (courseId) => {
    axios.put(`https://backend.azad.deloai.com/admin/student/students/${student.id}/unenrol/${courseId}`)
      .then(() => {
        setReload(!reload);
        setSuccessMessage('Student unenrolled successfully!');
        setErrorMessage('')
      })
      .catch(error => {
        setErrorMessage('There was an error unenrolling the student!');
        setSuccessMessage('')
      });
  };


  const handlePackageUnenroll = (courseId) => {
    axios.put(`https://backend.azad.deloai.com/admin/student/students/${student.id}/unenrolpackage/${courseId}`)
      .then(() => {
        setReload(!reload);
        setSuccessMessage('Student unenrolled successfully!');
        setErrorMessage('')
      })
      .catch(error => {
        setErrorMessage('There was an error unenrolling the student!');
        setSuccessMessage('')
      });
  };
 
  const handlePackageEnroll = (courseId) => {
    const days = prompt('Enter number of days for the enrollment:');
    if (days && !isNaN(days)) {
      axios.put(`https://backend.azad.deloai.com/admin/student/students/${student.id}/enrolpackage/${courseId}`, { number_of_days: days })
        .then(() => {
          setReload(!reload);
          setSuccessMessage('Student enrolled successfully!');
          setErrorMessage('')
        })
        .catch(error => {
          setErrorMessage('There was an error enrolling the student!');
          setSuccessMessage('')
        });
    }
  };
  const handleEnroll = (courseId) => {
    const days = prompt('Enter number of days for the enrollment:');
    if (days && !isNaN(days)) {
      axios.put(`https://backend.azad.deloai.com/admin/student/students/${student.id}/enrol/${courseId}`, { number_of_days: days })
        .then(() => {
          setReload(!reload);
          setSuccessMessage('Student enrolled successfully!');
          setErrorMessage('')
        })
        .catch(error => {
          setErrorMessage('There was an error enrolling the student!');
          setSuccessMessage('')
        });
    }
  };

  const handleUpdateEnrollmentDays = (courseId) => {
    const additionalDays = prompt('Enter additional number of days for the enrollment:');
    if (additionalDays && !isNaN(additionalDays)) {
      axios.put(`https://backend.azad.deloai.com/admin/student/students/${student.id}/update_enrollment/${courseId}`, { additional_days: parseInt(additionalDays) })
        .then(response => {
          setReload(!reload);
          setSuccessMessage('Number of days updated successfully!');
          setErrorMessage('')
        })
        .catch(error => {
          setErrorMessage('There was an error updating the number of days!');
          setSuccessMessage('')
        });
    }
  };
  const handleUpdatePackageEnrollmentDays = (courseId) => {
    const additionalDays = prompt('Enter additional number of days for the enrollment:');
    if (additionalDays && !isNaN(additionalDays)) {
      axios.put(`https://backend.azad.deloai.com/admin/student/students/${student.id}/update_package_enrollment/${courseId}`, { additional_days: parseInt(additionalDays) })
        .then(response => {
          setReload(!reload);
          setSuccessMessage('Number of days updated successfully!');
          setErrorMessage('')
        })
        .catch(error => {
          setErrorMessage('There was an error updating the number of days!');
          setSuccessMessage('')
        });
    }
  };

  if (!student) {
    return <div>Loading...</div>;
  }

  const filteredEnrolledCourses = student.courses_enrolled.filter(course =>
    course.name.toLowerCase().includes(enrolledSearch.toLowerCase())
  );
  const filteredEnrolledPackages = student.packages_enrolled.filter(course =>
    course.name.toLowerCase().includes(enrolledPackageSearch.toLowerCase())
  );
  
  const filteredAllCourses = courses.filter(course =>
    course.name.toLowerCase().includes(allCoursesSearch.toLowerCase())
  );
  const filteredAllPackages = packages.filter(course =>
    course.name.toLowerCase().includes(allPackageSearch.toLowerCase())
  );


  return (
    <div className="student-detail">
      <HomeVerticalNavbar />
      <div className="content2">
        <div className='headingbar'>
          <h2 className='heading2'>Student Detail</h2>
        </div>
        {successMessage && <div className="alert success">{successMessage}</div>}
        {errorMessage && <div className="alert error">{errorMessage}</div>}
        <div className="student-info">
          <p><strong>Name:</strong> {student.name}</p>
          <p><strong>Contact Number:</strong> {student.contact_number}</p>
          <p><strong>Email ID:</strong> {student.email_id}</p>
          <p><strong>Student ID:</strong> {student.student_id}</p>
          <p><strong>IP Address:</strong> {student.ip_address}</p>
          <p><strong>Date Created:</strong> {student.date_created}</p>
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <button onClick={handleResetIp}>Reset IP Address</button>
            <button onClick={handleChangePassword}>Change Password</button>
            <button onClick={handleDelete} style={{ backgroundColor: 'red', color: 'white' }}>Delete Student</button>
          </div>
        </div>

        {student.courses_enrolled.length > 0 && <h2 className='heading2'>Courses Enrolled</h2>}
        {student.courses_enrolled.length > 0 && <input
          type="text"
          placeholder="Search enrolled courses..."
          value={enrolledSearch}
          onChange={(e) => setEnrolledSearch(e.target.value)}
        />}
        <div className="course-cards">
          {filteredEnrolledCourses.map(course => (
            <div className="course-card" key={course.course_id}>
              <img
                src={course.thumbnail_url}
                alt={course.name}
                className="course-thumbnail2"
              />
              <div className="course-content">
                <h3 className="course-title">
                  {course.name.length > 100 ? `${course.name.substring(0, 100)}...` : course.name}
                </h3>
                <p>Course Code : {course.course_id}</p>
                <p>Enrolled Date: {new Date(course.enrolled_date).toLocaleDateString()}</p>
                <p>Expiry Date: {new Date(course.expiry_date).toLocaleDateString()}</p>
                <button className="unenroll-button" onClick={() => handleUnenroll(course.course_id)}>
                  Unenroll
                </button>
                <button className="update-days-button" onClick={() => handleUpdateEnrollmentDays(course.id)}>
                  Update Days
                </button>
              </div>
            </div>
          ))}
        </div>


        {student.packages_enrolled.length > 0 && <h2 className='heading2'>Packages Enrolled</h2>}
        {student.packages_enrolled.length > 0 && <input
          type="text"
          placeholder="Search enrolled courses..."
          value={enrolledPackageSearch}
          onChange={(e) => setEnrolledPackageSearch(e.target.value)}
        />}
        <div className="course-cards">
          {filteredEnrolledPackages.map(course => (
            <div className="course-card" key={course.package_id}>
              <img
                src={course.thumbnail_url}
                alt={course.name}
                className="course-thumbnail2"
              />
              <div className="course-content">
                <h3 className="course-title">
                  {course.name.length > 100 ? `${course.name.substring(0, 100)}...` : course.name}
                </h3>
                <p>Course Code : {course.package_id}</p>
                <p>Enrolled Date: {new Date(course.enrolled_date).toLocaleDateString()}</p>
                <p>Expiry Date: {new Date(course.expiry_date).toLocaleDateString()}</p>
                <button className="unenroll-button" onClick={() => handlePackageUnenroll(course.package_id)}>
                  Unenroll
                </button>
                <button className="update-days-button" onClick={() => handleUpdatePackageEnrollmentDays(course.id)}>
                  Update Days
                </button>
              </div>
            </div>
          ))}
        </div>

        <h2 className='heading2'>All Courses</h2>
        <input
          type="text"
          placeholder="Search all courses..."
          value={allCoursesSearch}
          onChange={(e) => setAllCoursesSearch(e.target.value)}
        />
        <div className="course-cards">
          {filteredAllCourses.map(course => (
            <div className="course-card" key={course.id}>
              <img src={course.thumbnail_url} alt={course.name} className="course-thumbnail2" />
              <div className="course-content">
                <h3 className="course-title">
                  {course.name.length > 100 ? `${course.name.substring(0, 100)}...` : course.name}
                </h3>
                <p>Course Code : {course.course_id}</p>
                <button className="enroll-button" onClick={() => handleEnroll(course.id)}>
                  Enroll
                </button>
              </div>
            </div>
          ))}
        </div>


        <h2 className='heading2'>All Packages</h2>
        <input
          type="text"
          placeholder="Search all courses..."
          value={allPackageSearch}
          onChange={(e) => setAllPackageSearch(e.target.value)}
        />
        <div className="course-cards">
          {filteredAllPackages.map(course => (
            <div className="course-card" key={course.id}>
              <img src={course.thumbnail_url} alt={course.name} className="course-thumbnail2" />
              <div className="course-content">
                <h3 className="course-title">
                  {course.name.length > 100 ? `${course.name.substring(0, 100)}...` : course.name}
                </h3>
                <p>Course Code : {course.package_id}</p>
                <button className="enroll-button" onClick={() => handlePackageEnroll(course.id)}>
                  Enroll
                </button>
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default StudentDetail;
