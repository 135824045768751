import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import Authentication from '../../Models/Authentication/Authentication';
import GoogleLoginButton from '../../components/GoogleLoginButton';
import './Login.css';
import { CLIENT_ID, API_KEY } from '../../config';
import logo from '../../assets/logo.jpeg'; // Make sure to add your logo image in the assets folder

function LoginForm() {
  const [message, setMessage] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get('code');
    const state = query.get('state'); // State is used to preserve original location
  
    if (code) {
      const fetchToken = async () => {
        const response = await fetch('https://oauth2.googleapis.com/token', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: new URLSearchParams({
            code,
            client_id: CLIENT_ID,
            client_secret: API_KEY,
            redirect_uri: 'https://admin.azad.deloai.com/oauth2callback',
                        // redirect_uri: 'http://localhost:3000/oauth2callback',

            grant_type: 'authorization_code',
          }),
        });
        const data = await response.json();
        if (data.access_token) {
          sessionStorage.setItem('access_token', data.access_token);
          login();
          if (state) {
            navigate(decodeURIComponent(state)); // Redirect back to original location
          }  
          else {
            navigate('/')
          }   
        } else {
          setMessage('Failed to authenticate with Google.');
        }
      };
      fetchToken();
    }
  }, [location, navigate, login]);


  return (
    <div className="login-container">
      <div className="header">
        <img src={logo} alt="Azad ICS Academy Logo" className="logo" />
        <h1>Azad ICS Academy</h1>
      </div>
      <div className="form-container">
        <GoogleLoginButton redirectTo={'/'} />

        {message && <p className="form-message">{message}</p>}
      </div>
    </div>
  );
}

export default LoginForm;
