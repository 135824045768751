import React from 'react';
import { CLIENT_ID, REDIRECT_URI } from '../config';
import { red } from '@mui/material/colors';

const GoogleLoginButton = ({ redirectTo }) => {
  console.log(redirectTo)
  const handleLogin = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${CLIENT_ID}&` +
      `redirect_uri=${encodeURIComponent(REDIRECT_URI)}&` +
      `response_type=code&` +
      `scope=https://www.googleapis.com/auth/calendar` +
      `&state=${encodeURIComponent(redirectTo)}`; // Store the redirect state
    
    window.location.href = authUrl;
  };

  return (
    <button onClick={handleLogin}>
      Login with Google
    </button>
  );
};

export default GoogleLoginButton;

