import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HomeVerticalNavbar from '../../components/VerticalNavBars/HomePage';
import './PackageScreen.css';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { uploadFile } from '../../upload-vedio';

const PackageScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reload,setReload] = useState(true)
  const [newPackage, setNewPackage] = useState({
    name: '',
    topics: '',
    professors: '',
    price: '',
    thumbnail_url: ''
  });
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    axios.get('https://backend.azad.deloai.com/admin/package/packages',
      {
        params: {
          limit: 10000, // Large number to ensure all courses are fetched
          offset: 0
        }
      }
    )
      .then(response => {
        setPackages(response.data);
        setFilteredPackages(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the packages!', error);
      });
  }, [reload]);

  useEffect(() => {
    if (searchQuery) {
      setFilteredPackages(packages.filter(packageItem =>
        packageItem.name.toLowerCase().includes(searchQuery.toLowerCase())
      ));
    } else {
      setFilteredPackages(packages);
    }
  }, [searchQuery, packages]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (e) => {
    setNewPackage({ ...newPackage, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setThumbnailFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    setLoading(true)

    e.preventDefault();
    let thumbnailUrl = newPackage.thumbnail_url;

    if (thumbnailFile) {
      try {
        const url = await uploadFile(thumbnailFile, null, 'thumbnails');
        thumbnailUrl = url;
      } catch (error) {
        console.error('There was an error uploading the file!', error);
      }
    }

    axios.post('https://backend.azad.deloai.com/admin/package/packages', {
      ...newPackage,
      thumbnail_url: thumbnailUrl,
      topics: newPackage.topics.split(','),
      professors: newPackage.professors.split(',')
    })
      .then(response => {
        closeModal();

        alert('Package added successfully!');
        setNewPackage(
          {
            name: '',
            topics: '',
            professors: '',
            price: '',
            thumbnail_url: ''
          }
        )
        setThumbnailFile(null);

        setReload(!reload)
        setLoading(false)
        


      })
      .catch(error => {
        console.error('There was an error adding the package!', error);
        alert('Failed to add package. Please try again.');
        setLoading(false)

      });
      setLoading(false)
      closeModal()
  };

  const deletePackage = (packageId) => {
    if (window.confirm(`Are you sure you want to delete this package?`)) {
      axios.delete(`https://backend.azad.deloai.com/admin/package/packages/${packageId}`)
        .then(() => {
          setPackages(packages.filter(packageItem => packageItem.package_id !== packageId));
          setFilteredPackages(filteredPackages.filter(packageItem => packageItem.package_id !== packageId));
        })
        .catch(error => {
          console.error('There was an error deleting the package!', error);
        });
    }
  };

  return (
    <div className="package-screen">
      <HomeVerticalNavbar />

      <div className="content">
        <h2 className='heading2'>All Packages</h2>
        <div style={{display:"flex" ,flexDirection:"row", justifyContent:"space-between"}}>
          <input 
            type="text" 
            placeholder="Search Packages" 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
          <button className="add-button" onClick={openModal}>Add Package</button>
        </div>
        
        <div className="course-cards">
          {filteredPackages.map(packageItem => (
            <div className="course-card" key={packageItem.package_id}>
              <img src={packageItem.thumbnail_url} alt={packageItem.name} className="course-thumbnail2" />
              <div className="course-content">
                <h3 className="course-title">{packageItem.name.length > 100 ? `${packageItem.name.substring(0, 100)}...` : packageItem.name}</h3>
                <p>Package Code : {packageItem.package_id}</p>

              </div>
              <div className="course-buttons">
                <Link 
                  to={`/package/build/${packageItem.package_id}`}  
                  style={{
                    textDecorationLine: "none",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#4caf50",
                    width: "35%",
                    height: "40px",
                    marginRight:"20px",
                    borderRadius:"5px"
                  }}
                >
                  Package Builder
                </Link>
                <Link 
                  to={`/package/edit/${packageItem.package_id}`}  
                  style={{
                    textDecorationLine: "none",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#4caf50",
                    width: "25%",
                    height: "40px",
                    marginRight:"20px",
                    borderRadius:"5px"
                  }}
                >
                  More
                </Link>
                <button 
                  className="delete-button" 
                  onClick={() => deletePackage(packageItem.package_id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Package"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Add Package</h2>
        {loading ? (
          <div className="loading-spinner"></div>
        ) : (
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" name="name" value={newPackage.name} onChange={handleChange} required />
          </label>
          <label>
            Topics (comma separated):
            <input type="text" name="topics" value={newPackage.topics} onChange={handleChange} />
          </label>
          <label>
            Professors (comma separated):
            <input type="text" name="professors" value={newPackage.professors} onChange={handleChange} />
          </label>
          <label>
            Price:
            <input type="number" name="price" value={newPackage.price} onChange={handleChange} step="0.01" required />
          </label>
          <label>
            <input type="text" hidden name="whole_duration" value='' onChange={handleChange} />
          </label>
          <label>
            Thumbnail:
            <input type="file" accept="image/*" onChange={handleFileChange} />
          </label>
          <button type="submit">Add Package</button>
          <button type="button" onClick={closeModal} className="cancel">Cancel</button>
        </form>
        )}
      </Modal>
    </div>
  );
};

export default PackageScreen;
