import React from 'react';
import { Navigate } from 'react-router-dom';
import ScheduleEvent from '../components/ScheduleEvent';

const ScheduleEventPage = () => {
  // Fetch the access token from session storage
  const token = sessionStorage.getItem('access_token');

  // If no access token is found, redirect to the login page
  if (!token) {
    return <Navigate to="/login" />;
  }

  // If access token is available, render the ScheduleEvent component
  return (
    <div>
      <h1>Schedule a New Event</h1>
      <ScheduleEvent token={token} />
    </div>
  );
};

export default ScheduleEventPage;
