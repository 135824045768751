import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HomeVerticalNavbar from '../../components/VerticalNavBars/HomePage';
const TestReportScreen = () => {
    const { testId, studentId } = useParams();
    const navigate = useNavigate();
    const [test, setTest] = useState(null);
    const [userResponses, setUserResponses] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [student, setStudent] = useState(null);


    const fetchTestReport = async () => {
        try {
            const response = await fetch(`https://backend.azad.deloai.com/admin/test/tests/${testId}/result?student_id=${studentId}`);
            const response2 = await fetch(`https://backend.azad.deloai.com/admin/student/students/${studentId}`);

            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            const data2 = await response2.json()
            console.log(data)
            setStudent(data2)
            setTest(data);
            setUserResponses(data.result.responses);
        } catch (error) {
            console.error("Error fetching test report:", error);
            alert(error.message || 'Something went wrong while fetching the report.');
        }
    };

    useEffect(() => {
        fetchTestReport();
    }, []);

    if (!test) return <div style={styles.loading}>Loading...</div>;

    const currentQuestion = test.questions[currentIndex];
    const userResponse = userResponses[currentIndex]?.answer;
    const correctOptionKey = ['A', 'B', 'C', 'D'][currentQuestion.correct_option];
    const correctOptionText = currentQuestion.options[correctOptionKey].text;

    let statusMessage = '';
    let statusColor = '';

    if (userResponse === 'NA') {
        statusMessage = `Not Selected (Correct: ${correctOptionText})`;
        statusColor = 'red';
    } else if (userResponse === correctOptionKey) {
        statusMessage = 'Correct!';
        statusColor = 'green';
    } else {
        statusMessage = `Wrong! (Correct: ${correctOptionText})`;
        statusColor = 'red';
    }

    return (
       <div>
        <HomeVerticalNavbar/>
       <div className='content'>
       <div style={styles.container} >


            
<h2 style={styles.title}>Test Name :{test.name}</h2>
<p style={styles.score}>Student ID: {student.student_id}</p>

<p style={styles.score}>Student Name: {student.name}</p>

<p style={styles.score}>Student Score: {test.result.score}</p>

<div style={styles.questionContainer}>
    <p style={styles.questionText}>{currentIndex + 1}. {currentQuestion.question}</p>
    
    <div style={styles.options}>
        {['A', 'B', 'C', 'D'].map((option) => {
            const isCorrect = option === correctOptionKey;
            console.log(                            currentQuestion.options[option].text
            )
            return (
                <div key={option} style={styles.option}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input 
                            type="radio" 
                            name="options" 
                            value={option} 
                            checked={userResponse === option} 
                            readOnly 
                            style={styles.radioInput} 
                        />
                        <label style={{
                            ...styles.optionLabel,
                            whiteSpace: 'nowrap',  // Prevent label text from breaking

                            ...(userResponse === option ? (isCorrect ? styles.correct : styles.incorrect) : isCorrect ? styles.correct : {})
                        }}>
                            {currentQuestion.options[option].text}
                        </label>
                    </div>
                </div>
            );
            
        })}
    </div>

    <p style={{ ...styles.statusText, color: statusColor }}>
        {statusMessage}
    </p>
</div>

<div style={styles.buttonContainer}>
    <button onClick={() => setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0))} disabled={currentIndex === 0} style={styles.button}>
        Previous
    </button>
    <button onClick={() => setCurrentIndex(prevIndex => Math.min(prevIndex + 1, test.questions.length - 1))} disabled={currentIndex === test.questions.length - 1} style={styles.button}>
        Next
    </button>
</div>

<button onClick={() => navigate(`/tests/${testId}`)} style={styles.backButton}>
    Back to Tests
</button>
</div>
       </div>
       </div>
    );
};

const styles = {
    container: {
        maxWidth: '600px',
        margin: '20px auto',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        fontFamily: 'Arial, sans-serif',
    },
    title: {
        textAlign: 'center',
        color: '#333',
        marginBottom: '10px',
    },
    score: {
        textAlign: 'center',
        color: '#555',
        fontSize: '1.2em',
        marginBottom: '20px',
    },
    questionContainer: {
        marginBottom: '20px',
        padding: '15px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        backgroundColor: '#f9f9f9',
    },
    questionText: {
        fontSize: '1.1em',
        color: '#333',
        marginBottom: '15px',
    },
    options: {
        marginTop: '10px',
    },
    option: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px', // Reduced gap
    },
    radioInput: {
        marginRight: '5px', // Reduced gap
    },
    optionLabel: {
        fontSize: '1em',
        color: '#444',
    },
    correct: {
        fontWeight: 'bold',
        color: 'green',
    },
    incorrect: {
        fontWeight: 'bold',
        color: 'red',
    },
    statusText: {
        fontSize: '1em',
        marginTop: '10px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    button: {
        padding: '10px 15px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#007BFF',
        color: 'white',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
    backButton: {
        marginTop: '20px',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#28a745',
        color: 'white',
        cursor: 'pointer',
        width: '100%',
        transition: 'background-color 0.3s',
    },
    loading: {
        textAlign: 'center',
        fontSize: '1.5em',
    },
};

export default TestReportScreen;
