import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import Login from './screens/Authentication/Login';
import Logout from './screens/Authentication/Logout';
import StudentScreen from './screens/Students/StudentScreen';
import CourseScreen from './screens/Course/CourseScreen';
import CourseDetail from './screens/Course/CourseDetail';
import EditCourseScreen from './screens/Course/EditCourse';
import StudentDetail from './screens/Students/StudentDetail';
import PackageScreen from './screens/Packages/PackageScreen';
import EditPackageScreen from './screens/Packages/EditPackage';
import PackageBuilderScreen from './screens/Packages/PackageBuilder';
import ViewTestScreen from './screens/Tests/ViewTests';
import './App.css';
import ScheduleEventPage from './screens/ScheduleEvent';
import TestScreen from './screens/Tests/Tests';
import QuestionScreen from './screens/Questions/Question';
import TestQuestionsScreen from './screens/Questions/TestQuestionScreen';
import TestReportScreen from './screens/Tests/TestReport';
function App() {

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/oauth2callback" element={<Login />} />
            <Route path="/schedule-event" element={<ScheduleEventPage />} />

            <Route path="/logout" element={<Logout />} />
            <Route path="/" element={<ProtectedRoute element={StudentScreen} />} />
            <Route path="/courses" element={<ProtectedRoute element={CourseScreen} />} />
            <Route path="/courses/:courseId" element={<ProtectedRoute element={CourseDetail} />} />
            <Route path="/course/edit/:course_id" element={<ProtectedRoute element={EditCourseScreen} />} />
            <Route path="/students/:studentId" element={<ProtectedRoute element={StudentDetail} />} />
            <Route path="/packages" element={<ProtectedRoute element={PackageScreen} />} />
            <Route path="/package/edit/:package_id" element={<ProtectedRoute element={EditPackageScreen} />} />
            <Route path="/package/build/:package_id" element={<ProtectedRoute element={PackageBuilderScreen} />} />
            <Route path="/tests" element={<ProtectedRoute element={TestScreen} />} />
            <Route path="/tests/:id" element={<ProtectedRoute element={ViewTestScreen} />} />
            <Route path="/questions/" element={<ProtectedRoute element={QuestionScreen} />} />
            <Route path="/tests/:testId/questions" element={<ProtectedRoute element={TestQuestionsScreen}/>} /> Route for viewing a test
            <Route path="/test-report/:testId/:studentId" element={<ProtectedRoute element={TestReportScreen}/>} />

            <Route path="/tests/:id" element={<ViewTestScreen />} /> Route for viewing a test

          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
