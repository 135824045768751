// // src/upload-video.js
import { s3 } from './aws-config'; // Ensure s3 is correctly configured


// export const uploadFile = (file,onProgress,path ='/') => {
//     const params = {
//       Bucket: 'azadicsacademy', // Replace with your bucket name
//       Key: `files/${path}/${file.name}`, // The file will be uploaded with this key
//       Body: file,
//       ContentType: file.type,
//     };
  
//     return new Promise((resolve, reject) => {
//       s3.upload(params)
//         .on('httpUploadProgress', (progress) => {
//           const percentCompleted = Math.round((progress.loaded * 100) / progress.total);
//           if (onProgress) {
//             onProgress(percentCompleted);
//           }
//         })
//         .send((err, data) => {
//           if (err) {
//             alert("Error Uploading file. Contact IT Team")
//             reject(err);
//           } else {
//             resolve(data.Location); // Return the URL of the uploaded file
//           }
//         });
//     });
//   };



export const uploadFile = (file, onProgress, path = '/') => {
  const params = {
    Bucket: 'azadicsacademy', // Replace with your bucket name
    Key: `files/${path}/${file.name}`, // The file will be uploaded with this key
    Body: file,
    ContentType: file.type,
  };

  const options = {
    partSize: 10 * 1024 * 1024, // 10 MB parts
    queueSize: 4, // Number of concurrent uploads
  };

  return new Promise((resolve, reject) => {
    s3.upload(params, options)
      .on('httpUploadProgress', (progress) => {
        const percentCompleted = Math.round((progress.loaded * 100) / progress.total);
        if (onProgress) {
          onProgress(percentCompleted);
        }
      })
      .send((err, data) => {
        if (err) {
          alert("Error uploading file. Please contact the IT team.");
          reject(err);
        } else {
          resolve(data.Location); // Return the URL of the uploaded file
        }
      });
  });
};
