import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HomeVerticalNavbar from '../../components/VerticalNavBars/HomePage';
import './TestScreen.css';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const TestScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newTest, setNewTest] = useState({
    name: '',
    start_date: '',
    start_time: '',
    duration: '',
    end_date: '',
    end_time: ''
  });
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    axios.get('https://backend.azad.deloai.com/admin/test/tests', {
      params: {
        limit: 10000,
        offset: 0,
      },
    })
    .then(response => {
      setTests(response.data);
      setFilteredTests(response.data);
    })
    .catch(error => {
      console.error('There was an error fetching the tests!', error);
    });
  }, []);

  useEffect(() => {
    if (searchQuery) {
      setFilteredTests(tests.filter(test =>
        test.name.toLowerCase().includes(searchQuery.toLowerCase())
      ));
    } else {
      setFilteredTests(tests);
    }
  }, [searchQuery, tests]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (e) => {
    console.log(e.target.value)
    setNewTest({ ...newTest, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    axios.post('https://backend.azad.deloai.com/admin/test/tests', {
      ...newTest,
    })
    .then(response => {
      setTests([...tests, response.data]);
      setFilteredTests([...filteredTests, response.data]);
      setNewTest({
        name: '',
        start_date: '',
        start_time: '',
        duration: '',
        end_date: '',
        end_time: ''
      });

      closeModal();
      alert('Test added successfully!');
    })
    .catch(error => {
      console.error('There was an error adding the test!', error);
      alert('Failed to add test. Please try again.');
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const deleteTest = (testId) => {
    if (window.confirm(`Are you sure you want to delete this test?`)) {
      axios.delete(`https://backend.azad.deloai.com/admin/test/tests/${testId}`)
        .then(() => {
          setTests(tests.filter(test => test.id !== testId));
          setFilteredTests(filteredTests.filter(test => test.id !== testId));
        })
        .catch(error => {
          console.error('There was an error deleting the test!', error);
        });
    }
  };

  return (
    <div className="test-screen">
      <HomeVerticalNavbar />

      <div className="content">
        <h2 className='heading2'>All Tests</h2>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <input 
            type="text" 
            placeholder="Search Tests" 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
          <button className="add-button" onClick={openModal}>Add Test</button>
        </div>

        <table className="student-table">
          <thead>
            <tr>
              <th>Test Name</th>
              <th>Start Date</th>
              <th>Start Time</th>
              <th>Duration</th>
              <th>End Date</th>
              <th>End Time</th>
              <th>View More</th>
              <th>Add Questions</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredTests.map(test => (
              <tr key={test.id}>
                <td>{test.name}</td>
                <td>{new Date(test.start_date).toLocaleDateString()}</td>
                <td>{test.start_time}</td>
                <td>{test.duration} minutes</td>
                <td>{new Date(test.end_date).toLocaleDateString()}</td>
                <td>{test.end_time}</td>
                <td>
                  <Link to={`/tests/${test.id}`} className="view-button">
                    View Test
                  </Link>
                </td>
                <td>
                  <Link to={`/tests/${test.id}/questions`} className="add-questions-button">
                    Add Questions
                  </Link>
                </td>
                <td>
                  <button 
                    className="delete-button" 
                    onClick={() => deleteTest(test.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Test"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Add Test</h2>
        {loading ? (
          <div className="loading-spinner"></div>
        ) : (
          <form onSubmit={handleSubmit}>
            <label>
              Name:
              <input type="text" name="name" value={newTest.name} onChange={handleChange} required />
            </label>
            <label>
              Start Date:
              <input type="date" name="start_date" value={newTest.start_date} onChange={handleChange} required />
            </label>
            <label>
              Start Time:
              <input type="time" name="start_time" value={newTest.start_time} onChange={handleChange} required />
            </label>
            <label>
              Duration (minutes):
              <input type="number" name="duration" value={newTest.duration} onChange={handleChange} required />
            </label>
            <label>
              End Date:
              <input type="date" name="end_date" value={newTest.end_date} onChange={handleChange} required />
            </label>
            <label>
              End Time:
              <input type="time" name="end_time" value={newTest.end_time} onChange={handleChange} required />
            </label>
           
            <button type="submit" disabled={loading}>Add Test</button>
            <button type="button" onClick={closeModal} className="cancel">Cancel</button>
          </form>
        )}
      </Modal>
    </div>
  );
};

export default TestScreen;
