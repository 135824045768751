import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Modal,
  TextField,
  Box,
  InputLabel,MenuItem
} from '@mui/material';
import { uploadFile } from '../../upload-vedio';

import { useParams } from 'react-router-dom';
import HomeVerticalNavbar from '../../components/VerticalNavBars/HomePage';
const TestQuestionsScreen = () => {
  const { testId } = useParams();
  const [test, setTest] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    question: '',
    question_image: null,
    option_a: { text: '', image_url: '' },
    option_b: { text: '', image_url: '' },
    option_c: { text: '', image_url: '' },
    option_d: { text: '', image_url: '' },
    correct_option: 0,
    crt_ans_score:2.0,
    wrong_ans_score: -0.25,
    question_type: 'General', // Default value

  });
  const [editMode, setEditMode] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState({
    question_image: null,
    option_a_image: null,
    option_b_image: null,
    option_c_image: null,
    option_d_image: null,
  });
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%', // Wider modal for better display
    maxHeight: '80vh', // Limit the height
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto', // Make modal scrollable
    // display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center', // Corrected from alignProperty to alignItems
    justifyContent: 'center',
    outline: 'none', // Prevents Chrome from applying an outline that might cause issues
  };
  const [selectedQuestionType, setSelectedQuestionType] = useState('All'); // State for selected question type

  useEffect(() => {
    fetchTest();
  }, [testId]);

  const handleFileChange = (e, key) => {
    setSelectedFiles({ ...selectedFiles, [key]: e.target.files[0] });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let updatedFormData = { ...formData };

    if (selectedFiles.question_image) {
      updatedFormData.question_image = await uploadFile(selectedFiles.question_image, null, 'questions');
    }
    for (let option of ['option_a', 'option_b', 'option_c', 'option_d']) {
      const fileKey = `${option}_image`;
      if (selectedFiles[fileKey]) {
        const uploadedUrl = await uploadFile(selectedFiles[fileKey], null, 'options');
        updatedFormData[option].image_url = uploadedUrl;
      }
    }

    try {
      if (editMode) {
        console.log("form da")
        console.log(updatedFormData)
        await axios.put(`https://backend.azad.deloai.com/admin/test/tests/${testId}/questions/${currentQuestion.id}`, updatedFormData);
      } else {
        await axios.post(`https://backend.azad.deloai.com/admin/test/tests/${testId}/add_question`, updatedFormData);
      }
      fetchTest()
      // fetchQuestions(currentPage);
      handleCloseQuestionModal();
    } catch (error) {
      console.error("Error saving question:", error);
    }
  };
  const fetchTest = async () => {
    const response = await axios.get(`https://backend.azad.deloai.com/admin/test/tests/${testId}`);
    setTest(response.data);
    setQuestions(response.data.questions);
  };
  const getOptionLetter = (index) => {
    const options = ['A', 'B', 'C', 'D'];

    if (index >= 0 && index <= 3) {
      return options[index];
    } else {
      throw new Error("Input must be between 0 and 3");
    }
  };
  const handleCloseQuestionModal = () => {
    setQuestionModalOpen(false);
    setEditMode(false);
    setCurrentQuestion(null);
    setSelectedFiles({
      question_image: null,
      option_a_image: null,
      option_b_image: null,
      option_c_image: null,
      option_d_image: null,
    });
  };  const handleOpenModal = () => {
    setModalOpen(true);
    fetchAvailableQuestions();
  };
  const handleOpenQuestioModal = (question = null) => {
    // setModalOpen(true);
    console.log(question)
    setQuestionModalOpen(true)
    if (question) {
      setEditMode(true);
      setCurrentQuestion(question);
      setFormData({
        question: question.question,
        question_image: question.question_image || null,
        option_a: question.options.A || { text: '', image_url: '' },
        option_b: question.options.B || { text: '', image_url: '' },
        option_c: question.options.C || { text: '', image_url: '' },
        option_d: question.options.D || { text: '', image_url: '' },
        correct_option: question.correct_option,
        crt_ans_score: question.crt_ans_score || 2.0,
        wrong_ans_score: question.wrong_ans_score || -0.25,
        question_type:question.question_type


      });
    } else {
      setEditMode(false);
      setFormData({
        question: '',
        question_image: null,
        option_a: { text: '', image_url: '' },
        option_b: { text: '', image_url: '' },
        option_c: { text: '', image_url: '' },
        option_d: { text: '', image_url: '' },
        correct_option: 0,
        crt_ans_score: 2.0,
        wrong_ans_score: -0.25,
        question_type:'General'

      });
    }
  };
  useEffect(() => {
    fetchAvailableQuestions();
  }, [searchQuery,selectedQuestionType]);
  
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  
  const fetchAvailableQuestions = async () => {
    const response = await axios.get(`https://backend.azad.deloai.com/admin/question/questions/type/${selectedQuestionType}?search=${searchQuery}`);
    setAvailableQuestions(response.data.questions);
  };
  
 

  const addQuestionToTest = async (questionId) => {
    await axios.post(`https://backend.azad.deloai.com/admin/test/tests/${testId}/add_question`, { question_id: questionId });
    fetchTest();
    setModalOpen(false);
  };

  const removeQuestionFromTest = async (questionId) => {
    await axios.delete(`https://backend.azad.deloai.com/admin/test/test/${testId}/remove_question/${questionId}`, {
    });
    fetchTest();
  };

  return (
   <div>
                      <HomeVerticalNavbar />

    <div className='content'>
    <Container>
      <Typography variant="h4">Test Questions</Typography>
      <div style={{display:'flex', gap:'10px'}}>
      <Button variant="contained" onClick={() => handleOpenQuestioModal()}>
        Add Question 
      </Button>
      <Button variant="contained" onClick={handleOpenModal}>
      
        Add Question from Template
      </Button>
      </div>
      

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>Sl no</TableCell>

            <TableCell>Question</TableCell>
          <TableCell>Options</TableCell>
          <TableCell>Correct Option</TableCell>
          <TableCell>View/Edit</TableCell>

          <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map((question,index) => (
              <TableRow key={question.id}>
                               <TableCell>{index+1}</TableCell>

   <TableCell>{question.question}</TableCell>
            <TableCell>
            <Box>
          {Object.entries(question.options).map(([key, option]) => (
            <Box key={key} sx={{ mb: 1 }}>
              <Typography variant="body2">{key}. {option.text}</Typography>
              {option.image_url && (
                <img
                  src={option.image_url}
                  alt={`${key} image`}
                  style={{ width: '100px', height: '100px', objectFit: 'contain', border: '1px solid black' }}
                />
              )}
            </Box>
          ))}
        </Box>
            </TableCell>
            {/* <TableCell>{question.correct_option}</TableCell> */}
            <TableCell>
              {getOptionLetter(question.correct_option)}
            </TableCell>
                        <TableCell>
                        <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ mr: 1 }}
                onClick={() => handleOpenQuestioModal(question)}
              >
                Edit
              </Button>
                 
                </TableCell>
                <TableCell>
                <Button onClick={() => removeQuestionFromTest(question.id)} color="error">
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={questionModalOpen} onClose={handleCloseQuestionModal}>
  <Box sx={style}>
    <Typography variant="h6" component="h2">
      {editMode ? 'Edit Question' : 'Add Question'}
    </Typography>
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label="Question"
        value={formData.question}
        onChange={(e) => setFormData({ ...formData, question: e.target.value })}
        sx={{
          mb: 2,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none', // Remove border
            },
            '&:hover fieldset': {
              border: 'none', // Remove border on hover
            },
            '&.Mui-focused fieldset': {
              border: 'none', // Remove border when focused
            },
          },
        }}         />

{
  !editMode &&(
    <TextField
  fullWidth
  select
  label="Question Type"
  value={formData.question_type}
  onChange={(e) => setFormData({ ...formData, question_type: e.target.value })}
  sx={{
    mb: 2,
   
  }}
>
  <MenuItem value="General">General</MenuItem>
  <MenuItem value="Indian History">Indian History</MenuItem>
  <MenuItem value="ಭಾರತೀಯ ಇತಿಹಾಸ">ಭಾರತೀಯ ಇತಿಹಾಸ</MenuItem>
  <MenuItem value="Indian Constitution">Indian Constitution</MenuItem>
  <MenuItem value="ಭಾರತೀಯ ಸಂವಿಧಾನ">ಭಾರತೀಯ ಸಂವಿಧಾನ</MenuItem>
  <MenuItem value="Indian Economy">Indian Economy</MenuItem>
  <MenuItem value="ಭಾರತೀಯ ಅರ್ಥಶಾಸ್ತ್ರ">ಭಾರತೀಯ ಅರ್ಥಶಾಸ್ತ್ರ</MenuItem>
  <MenuItem value="Geography">Geography</MenuItem>
  <MenuItem value="ಭೂಗೋಳಶಾಸ್ತ್ರ">ಭೂಗೋಳಶಾಸ್ತ್ರ</MenuItem>
  <MenuItem value="Science">Science</MenuItem>
  <MenuItem value="ಸಾಮಾನ್ಯ ವಿಜ್ಞಾನ">ಸಾಮಾನ್ಯ ವಿಜ್ಞಾನ</MenuItem>
  <MenuItem value="Science and Technology">Science and Technology</MenuItem>
  <MenuItem value="ವಿಜ್ಞಾನ ಮತ್ತು ತಂತ್ರಜ್ಞಾನ">ವಿಜ್ಞಾನ ಮತ್ತು ತಂತ್ರಜ್ಞಾನ</MenuItem>
  <MenuItem value="Mental Ability">Mental Ability</MenuItem>
  <MenuItem value="ಮಾನಸಿಕ ಸಾಮರ್ಥ್ಯ">ಮಾನಸಿಕ ಸಾಮರ್ಥ್ಯ</MenuItem>
  <MenuItem value="Environmental and Ecology">Environmental and Ecology</MenuItem>
  <MenuItem value="ಪರಿಸರ">ಪರಿಸರ</MenuItem>
  <MenuItem value="Current Affairs">Current Affairs</MenuItem>
  <MenuItem value="ಪ್ರಚಲಿತ ಘಟನೆಗಳು">ಪ್ರಚಲಿತ ಘಟನೆಗಳು</MenuItem>
  <MenuItem value="Rural Development and Panchayath Raj Institution">Rural Development and Panchayath Raj Institution</MenuItem>
  <MenuItem value="ಗ್ರಾಮೀಣಾಭಿವೃದ್ದಿ ಮತ್ತು ಪಂಚಾಯತ್‌ ರಾಜ್ ಸಂಸ್ಥೆಗಳು">ಗ್ರಾಮೀಣಾಭಿವೃದ್ದಿ ಮತ್ತು ಪಂಚಾಯತ್‌ ರಾಜ್ ಸಂಸ್ಥೆಗಳು</MenuItem>
  <MenuItem value="Co-operative movement">Co-operative movement</MenuItem>
  <MenuItem value="ಸಹಕಾರ ಚಳುವಳಿ">ಸಹಕಾರ ಚಳುವಳಿ</MenuItem>
  <MenuItem value="Indian Society">Indian Society</MenuItem>
  <MenuItem value="ಭಾರತೀಯ ಸಮಾಜ">ಭಾರತೀಯ ಸಮಾಜ</MenuItem>
  <MenuItem value="Land Reforms">Land Reforms</MenuItem>
  <MenuItem value="ಕರ್ನಾಟಕದ ಭೂ ಸುಧಾರಣೆಗಳು">ಕರ್ನಾಟಕದ ಭೂ ಸುಧಾರಣೆಗಳು</MenuItem>
  <MenuItem value="English">English</MenuItem>
  <MenuItem value="ಇಂಗ್ಲೀಷ್">ಇಂಗ್ಲೀಷ್</MenuItem>
  <MenuItem value="Kannada">Kannada</MenuItem>
  <MenuItem value="ಕನ್ನಡ">ಕನ್ನಡ</MenuItem>
  <MenuItem value="Computer">Computer</MenuItem>
  <MenuItem value="ಕಂಪ್ಯೂಟರ್‌">ಕಂಪ್ಯೂಟರ್‌</MenuItem>
</TextField>
  )  
}
    {/* Question Image Section */}
<Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
  {formData.question_image && (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      <img
        src={formData.question_image}
        alt="Question"
        style={{ width: '100px', height: '100px', objectFit: 'contain',          border: '1px solid black',
        }} // Fixed size for question image
      />
      <Typography
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          backgroundColor: 'white',
          color: 'red',
        //   borderRadius: '50%',
          width: '24px',
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          fontSize:'36px',
          border: '1px solid black',   // Black border

        }}
        onClick={() => setFormData({ ...formData, question_image: null })}
      >
        -
      </Typography>
    </Box>
  )}
  <Box>
    <InputLabel>Upload Question Image</InputLabel>
    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'question_image')} />
  </Box>
</Box>


     {/* Options Section */}
{['option_a', 'option_b', 'option_c', 'option_d'].map((option, index) => (
  <Box key={option} sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
    <TextField
      fullWidth
      variant="outlined"
      label={`Option ${String.fromCharCode(65 + index)}`}
      value={formData[option].text}
      onChange={(e) => setFormData({ ...formData, [option]: { ...formData[option], text: e.target.value } })}
      sx={{
        mb: 2,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none', // Remove border
          },
          '&:hover fieldset': {
            border: 'none', // Remove border on hover
          },
          '&.Mui-focused fieldset': {
            border: 'none', // Remove border when focused
          },
        },
      }}       />
    {formData[option].image_url && (
      <Box sx={{ position: 'relative', display: 'inline-block' }}>
        <img
          src={formData[option].image_url}
          alt={`Option ${String.fromCharCode(65 + index)} image`}
          style={{ width: '100px', height: '100px', objectFit: 'contain',          border: '1px solid black',
          }} // Fixed size for option image
        />
        <Typography
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            backgroundColor: 'white',
            color: 'red',
            // borderRadius: '50%',
            width: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            fontSize:'36px',
          border: '1px solid black',
          }}
          onClick={() => setFormData({ ...formData, [option]: { ...formData[option], image_url: null } })}
        >
          -
        </Typography>
      </Box>
    )}
    <Box>
      <InputLabel>Upload Option {String.fromCharCode(65 + index)} Image</InputLabel>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleFileChange(e, `${option}_image`)}
      />
    </Box>
  </Box>
))}

      {/* <TextField
        fullWidth
        label="Correct Option"
        type="number"
        value={formData.correct_option}
        onChange={(e) => setFormData({ ...formData, correct_option: parseInt(e.target.value) })}
        sx={{
          mb: 2,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none', // Remove border
            },
            '&:hover fieldset': {
              border: 'none', // Remove border on hover
            },
            '&.Mui-focused fieldset': {
              border: 'none', // Remove border when focused
            },
          },
        }}         /> */}

<TextField
  fullWidth
  label="Correct Option"
  select
  value={formData.correct_option}
  onChange={(e) => setFormData({ ...formData, correct_option: parseInt(e.target.value) })}
  sx={{
    mb: 2,
   
  }}
>
  {[0, 1, 2, 3].map((option) => (
    <MenuItem key={option} value={option}>
      {getOptionLetter(option)}
    </MenuItem>
  ))}
</TextField>
      <TextField
fullWidth
label="Correct Answer Score"
type="number"
value={formData.crt_ans_score}
onChange={(e) => setFormData({ ...formData, crt_ans_score: parseFloat(e.target.value) })}
sx={{ mb: 2 }}
/>
<TextField
fullWidth
label="Wrong Answer Score"
type="number"
value={formData.wrong_ans_score}
onChange={(e) => setFormData({ ...formData, wrong_ans_score: parseFloat(e.target.value) })}
sx={{
  mb: 2,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none', // Remove border
    },
    '&:hover fieldset': {
      border: 'none', // Remove border on hover
    },
    '&.Mui-focused fieldset': {
      border: 'none', // Remove border when focused
    },
  },
}}   />

      <Button variant="contained" color="primary" type="submit" fullWidth>
        {editMode ? 'Update Question' : 'Add Question'}
      </Button>
    </form>
  </Box>
</Modal>
<Modal open={modalOpen} onClose={() => setModalOpen(false)}>
  <Box
    sx={{
      backgroundColor: 'white',
      padding: 3,
      borderRadius: 2,
      boxShadow: 24,
      maxWidth: 600,
      margin: 'auto',
      mt: '10%',
    }}
  >
    <Typography variant="h6">Add Question from Template</Typography>
    <Box sx={{ml:2 }}>
              <InputLabel id="question-type-label">Filter by Question Type</InputLabel>

<TextField
fullWidth
select
value={selectedQuestionType}
onChange={(e) => {
setSelectedQuestionType(e.target.value);
// setCurrentPage(1); // Reset to first page on new filter
}}
sx={{
mb: 2,
'& .MuiOutlinedInput-root': {
'& fieldset': {
border: 'none', // Remove border
},
'&:hover fieldset': {
border: 'none', // Remove border on hover
},
'&.Mui-focused fieldset': {
border: 'none', // Remove border when focused
},
},
}}
>
<MenuItem value="All">All</MenuItem>
<MenuItem value="General">General</MenuItem>
<MenuItem value="Indian History">Indian History</MenuItem>
<MenuItem value="ಭಾರತೀಯ ಇತಿಹಾಸ">ಭಾರತೀಯ ಇತಿಹಾಸ</MenuItem>
<MenuItem value="Indian Constitution">Indian Constitution</MenuItem>
<MenuItem value="ಭಾರತೀಯ ಸಂವಿಧಾನ">ಭಾರತೀಯ ಸಂವಿಧಾನ</MenuItem>
<MenuItem value="Indian Economy">Indian Economy</MenuItem>
<MenuItem value="ಭಾರತೀಯ ಅರ್ಥಶಾಸ್ತ್ರ">ಭಾರತೀಯ ಅರ್ಥಶಾಸ್ತ್ರ</MenuItem>
<MenuItem value="Geography">Geography</MenuItem>
<MenuItem value="ಭೂಗೋಳಶಾಸ್ತ್ರ">ಭೂಗೋಳಶಾಸ್ತ್ರ</MenuItem>
<MenuItem value="Science">Science</MenuItem>
<MenuItem value="ಸಾಮಾನ್ಯ ವಿಜ್ಞಾನ">ಸಾಮಾನ್ಯ ವಿಜ್ಞಾನ</MenuItem>
<MenuItem value="Science and Technology">Science and Technology</MenuItem>
<MenuItem value="ವಿಜ್ಞಾನ ಮತ್ತು ತಂತ್ರಜ್ಞಾನ">ವಿಜ್ಞಾನ ಮತ್ತು ತಂತ್ರಜ್ಞಾನ</MenuItem>
<MenuItem value="Mental Ability">Mental Ability</MenuItem>
<MenuItem value="ಮಾನಸಿಕ ಸಾಮರ್ಥ್ಯ">ಮಾನಸಿಕ ಸಾಮರ್ಥ್ಯ</MenuItem>
<MenuItem value="Environmental and Ecology">Environmental and Ecology</MenuItem>
<MenuItem value="ಪರಿಸರ">ಪರಿಸರ</MenuItem>
<MenuItem value="Current Affairs">Current Affairs</MenuItem>
<MenuItem value="ಪ್ರಚಲಿತ ಘಟನೆಗಳು">ಪ್ರಚಲಿತ ಘಟನೆಗಳು</MenuItem>
<MenuItem value="Rural Development and Panchayath Raj Institution">Rural Development and Panchayath Raj Institution</MenuItem>
<MenuItem value="ಗ್ರಾಮೀಣಾಭಿವೃದ್ದಿ ಮತ್ತು पंचायत್‌ ರಾಜ್ ಸಂಸ್ಥೆಗಳು">ಗ್ರಾಮೀಣಾಭಿವೃದ್ದಿ ಮತ್ತು पंचायत್‌ ರಾಜ್ ಸಂಸ್ಥೆಗಳು</MenuItem>
<MenuItem value="Co-operative movement">Co-operative movement</MenuItem>
<MenuItem value="ಸಹಕಾರ ಚಳುವಳಿ">ಸಹಕಾರ ಚಳುವಳಿ</MenuItem>
<MenuItem value="Indian Society">Indian Society</MenuItem>
<MenuItem value="ಭಾರತೀಯ ಸಮಾಜ">ಭಾರತೀಯ ಸಮಾಜ</MenuItem>
<MenuItem value="Land Reforms">Land Reforms</MenuItem>
<MenuItem value="ಕರ್ನಾಟಕದ ಭೂ ಸುಧಾರಣೆಗಳು">ಕರ್ನಾಟಕದ ಭೂ ಸುಧಾರಣೆಗಳು</MenuItem>
<MenuItem value="English">English</MenuItem>
<MenuItem value="ಇಂಗ್ಲೀಷ್">ಇಂಗ್ಲೀಷ್</MenuItem>
<MenuItem value="Kannada">Kannada</MenuItem>
<MenuItem value="ಕನ್ನಡ">ಕನ್ನಡ</MenuItem>
<MenuItem value="Computer">Computer</MenuItem>
<MenuItem value="ಕಂಪ್ಯೂಟರ್‌">ಕಂಪ್ಯೂಟರ್‌</MenuItem>
</TextField>
              </Box>
    <TextField
      label="Search Questions"
      variant="outlined"
      value={searchQuery}
      onChange={handleSearch}
      fullWidth
      sx={{
        mb: 2,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none', // Remove border
          },
          '&:hover fieldset': {
            border: 'none', // Remove border on hover
          },
          '&.Mui-focused fieldset': {
            border: 'none', // Remove border when focused
          },
        },
      }}      
       />

    <TableContainer sx={{ maxHeight: 300, overflowY: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Question</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {availableQuestions.map((question) => (
            <TableRow key={question.id}>
              <TableCell>{question.question}</TableCell>
              <TableCell>
                <Button onClick={() => addQuestionToTest(question.id)}>Add</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
</Modal>

    </Container>

    </div>
   </div>
  );
};

export default TestQuestionsScreen;
