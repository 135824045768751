import React from 'react';
import axios from 'axios';

function Logout() {
  const handleLogout = async () => {
    try {
      const response = await axios.post('https://backend.azad.deloai.com/logout');
      alert(response.data.message);
    } catch (error) {
      alert('Error logging out');
    }
  };

  return (
    <button onClick={handleLogout}>Logout</button>
  );
}

export default Logout;
