// src/aws-config.js
import AWS from 'aws-sdk';

// Configure AWS SDK with your credentials and region
const config = {
  region: 'ap-southeast-2', // e.g., 'us-east-1'
  credentials: new AWS.Credentials('AKIAQE3RO5ZSZ2H6DACZ', '0bwsLN6qFiF1ehGTYkzyppCDcSGyrDNpVupY34zZ'),
};

AWS.config.update(config);

export const s3 = new AWS.S3();



