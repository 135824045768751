import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HomeVerticalNavbar from '../../components/VerticalNavBars/HomePage';
import './CourseScreen.css';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { uploadFile } from '../../upload-vedio';
import { v4 as uuidv4 } from 'uuid';

const CourseScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newCourse, setNewCourse] = useState({
    name: '',
    topics: '',
    professors: '',
    price: '',
    whole_duration: '',
    thumbnail_url: ''
  });
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    axios.get('https://backend.azad.deloai.com/admin/course/courses', {
      params: {
        limit: 10000, // Large number to ensure all courses are fetched
        offset: 0
      }
    })
    .then(response => {
      setCourses(response.data);
      setFilteredCourses(response.data);
    })
    .catch(error => {
      console.error('There was an error fetching the courses!', error);
    });
  }, []);
  

  useEffect(() => {
    if (searchQuery) {
      setFilteredCourses(courses.filter(course =>
        course.name.toLowerCase().includes(searchQuery.toLowerCase())
      ));
    } else {
      setFilteredCourses(courses);
    }
  }, [searchQuery, courses]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (e) => {
    setNewCourse({ ...newCourse, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setThumbnailFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    let thumbnailUrl = newCourse.thumbnail_url;
    const uniqueId = uuidv4();


    if (thumbnailFile) {
      try {
        const url = await uploadFile(thumbnailFile, null, `thumbnails/${newCourse.name}-${uniqueId}`);
        thumbnailUrl = url;
      } catch (error) {
        console.error('There was an error uploading the file!', error);
      }
    }

    axios.post('https://backend.azad.deloai.com/admin/course/courses', {
      ...newCourse,
      thumbnail_url: thumbnailUrl,
      topics: newCourse.topics.split(','),
      professors: newCourse.professors.split(',')
    })
      .then(response => {
        setCourses([...courses, response.data]);
        setFilteredCourses([...filteredCourses, response.data]);
             // Reset form fields
      setNewCourse({
        name: '',
        topics: '',
        professors: '',
        price: '',
        whole_duration: '',
        thumbnail_url: ''
      });
      setThumbnailFile(null);

        closeModal();

        alert('Course added successfully!');

      })
      .catch(error => {
        console.error('There was an error adding the course!', error);
        alert('Failed to add course. Please try again.');

      });

      setLoading(false)
      closeModal()
  };

  const deleteCourse = (courseId) => {
    if (window.confirm(`Are you sure you want to delete this course?`)) {
      axios.delete(`https://backend.azad.deloai.com/admin/course/courses/${courseId}`)
        .then(() => {
          setCourses(courses.filter(course => course.course_id !== courseId));
          setFilteredCourses(filteredCourses.filter(course => course.course_id !== courseId));
        })
        .catch(error => {
          console.error('There was an error deleting the course!', error);
        });
    }
  };

  return (
    <div className="course-screen">
    <HomeVerticalNavbar />
  
    <div className="content">
      <h2 className='heading2'>All Courses</h2>
      <div  style={{display:"flex" ,flexDirection:"row", justifyContent:"space-between"}}>
        <input 
          type="text" 
          placeholder="Search Courses" 
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
        <button className="add-button" onClick={openModal}>Add Course</button>
      </div>
      
     

      <div className="course-cards">
        {filteredCourses.map(course => (
          <div className="course-card" key={course.course_id}>
            <img src={course.thumbnail_url} alt={course.name} className="course-thumbnail2" />
            <div className="course-content">
              <h3 className="course-title">{course.name.length > 100 ? `${course.name.substring(0, 100)}...` : course.name}</h3>
              <p>Course Code : {course.course_id}</p>

            </div>
            <div className="course-buttons">
              <Link 
                to={`/courses/${course.course_id}`} 
                style={{
                  textDecorationLine: "none",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#4caf50",
                  width: "35%",
                  height: "40px",
                  marginRight:"20px",
                  borderRadius:"5px"
                }}
              >
                Course Builder
              </Link>
              <Link 
                to={`/course/edit/${course.course_id}`}  
                style={{
                  textDecorationLine: "none",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#4caf50",
                  width: "25%",
                  height: "40px",
                  marginRight:"20px",
                  borderRadius:"5px"
                }}
              >
                More
              </Link>
              <button 
                className="delete-button" 
                onClick={() => deleteCourse(course.course_id)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Add Course"
      className="modal"
      overlayClassName="modal-overlay"
    >
      <h2>Add Course</h2>
      {loading ? (
          <div className="loading-spinner"></div>
        ) : (
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" name="name" value={newCourse.name} onChange={handleChange} required />
        </label>
        <label>
          Topics (comma separated):
          <input type="text" name="topics" value={newCourse.topics} onChange={handleChange} required />
        </label>
        <label>
          Professors (comma separated):
          <input type="text" name="professors" value={newCourse.professors} onChange={handleChange}  required/>
        </label>
        <label>
          Price:
          <input type="number" name="price" value={newCourse.price} onChange={handleChange} step="0.01" required />
        </label>
        <label>
          <input type="text" hidden name="whole_duration" value=''onChange={handleChange} />
        </label>
        <label>
          Thumbnail:
          <input type="file" accept="image/*" onChange={handleFileChange}  required/>
        </label>
        <button type="submit"             disabled={loading}
 >Add Course</button>
        <button type="button" onClick={closeModal} className="cancel">Cancel</button>
      </form>)}
    </Modal>
  </div>
  
  
  );
};

export default CourseScreen;
