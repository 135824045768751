import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HomeVerticalNavbar from '../../components/VerticalNavBars/HomePage';
import './StudentScreen.css';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
const generateRandomPassword = () => {
  return Math.floor(100000 + Math.random() * 900000).toString();
};

const StudentScreen = () => {
  const [students, setStudents] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detailModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [enrollModalIsOpen, setEnrollModalIsOpen] = useState(false);
  const [unenrollModalIsOpen, setUnenrollModalIsOpen] = useState(false);
  const [newStudent, setNewStudent] = useState({
    name: '',
    contact_number: '',
    email_id: '',
    ip_address: '',
    password: generateRandomPassword() // Initialize with a random 6-digit password
  });
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedEnrolledCourse, setSelectedEnrolledCourse] = useState('');
const [reload,setReload] =useState(true);
const [numberOfDays, setNumberOfDays] = useState(''); // State for number of days


  useEffect(() => {
    console.log('skdjksjdskjdkjs')
    axios.get('https://backend.azad.deloai.com/admin/student/students')
      .then(response => {
        console.log(response)
        setStudents(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the students!', error);
      });
  }, [reload]);

  useEffect(() => {
    axios.get('https://backend.azad.deloai.com/admin/course/courses')
      .then(response => {
        setCourses(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the courses!', error);
      });
  }, []);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const openDetailModal = (student) => {
    
// console.log(students)
// console.log(courses)
    setSelectedStudent(student);
    setDetailModalIsOpen(true);
  };

  const closeDetailModal = () => {
    setDetailModalIsOpen(false);
    setEnrollModalIsOpen(false); // Close the enroll modal if it's open
    setUnenrollModalIsOpen(false); // Close the unenroll modal if it's open
  };

  const openEnrollModal = () => setEnrollModalIsOpen(true);
  const openUnenrollModal = () => setUnenrollModalIsOpen(true);

  const handleChange = (e) => {
    setNewStudent({ ...newStudent, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://backend.azad.deloai.com/admin/student/students', newStudent)
      .then(response => {
        setStudents([...students, response.data]);
        setNewStudent({
          name: '',
          contact_number: '',
          email_id: '',
          ip_address: '',
          password: generateRandomPassword() // Initialize with a random 6-digit password
        })
        closeModal();
      })
      .catch(error => {
        // Check if error response exists and alert the user
        if (error.response) {
          alert(`Error: ${error.response.data.error}`);
        } else {
          alert('There was an error adding the student!');
        }
        console.error('There was an error adding the student!', error);
      });
  };
  

  const handleResetIp = () => {
    axios.put(`https://backend.azad.deloai.com/admin/student/students/${selectedStudent.id}/reset_ip`)
      .then(response => {
        const updatedStudent = response.data;
        setStudents(students.map(student => student.id === updatedStudent.id ? updatedStudent : student));
        closeDetailModal();
      })
      .catch(error => {
        console.error('There was an error resetting the IP address!', error);
      });
  };

  const handleChangePassword = () => {
    const newPassword = prompt('Enter new password:');
    if (newPassword) {
      axios.put(`https://backend.azad.deloai.com/admin/student/students/${selectedStudent.id}/change_password`, { new_password: newPassword })
        .then(response => {
          const updatedStudent = response.data;
          setStudents(students.map(student => student.id === updatedStudent.id ? updatedStudent : student));
          closeDetailModal();
        })
        .catch(error => {
          console.error('There was an error changing the password!', error);
        });
    }
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this student?')) {
      axios.delete(`https://backend.azad.deloai.com/admin/student/students/${selectedStudent.id}`)
        .then(() => {
          setStudents(students.filter(student => student.id !== selectedStudent.id));
          closeDetailModal();
        })
        .catch(error => {
          console.error('There was an error deleting the student!', error);
        });
    }
  };

  
  const handleEnroll = () => {
    axios.put(`https://backend.azad.deloai.com/admin/student/students/${selectedStudent.id}/enrol/${selectedCourse}`, { number_of_days: parseInt(numberOfDays) })
      .then(() => {
        closeEnrollModal();
        closeDetailModal();
        setReload(!reload);
      })
      .catch(error => {
        console.error('There was an error enrolling the student!', error);
      });
  };


  const handleUnenroll = () => {
    axios.put(`https://backend.azad.deloai.com/admin/student/students/${selectedStudent.id}/unenrol/${selectedEnrolledCourse}`)
      .then(() => {
        closeUnenrollModal();
        closeDetailModal();
        setReload(!reload)
      })
      .catch(error => {
        console.error('There was an error unenrolling the student!', error);
      });
  };

  const closeEnrollModal = () => setEnrollModalIsOpen(false);
  const closeUnenrollModal = () => setUnenrollModalIsOpen(false);

  return (
    <div className="student-screen">
      <HomeVerticalNavbar />
      <div className="content">
        <div className="top-bar">
          <button className="add-button" onClick={openModal}>Add</button>
        </div>
        <table className="student-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact Number</th>
              <th>Email ID</th>
              <th>Student ID</th>
              <th>IP Address</th>
              <th>Date Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {students.map(student => (
              <tr key={student.id}>
                <td>{student.name}</td>
                <td>{student.contact_number}</td>
                <td>{student.email_id}</td>
                <td>{student.student_id}</td>
                <td>{student.ip_address}</td>
                <td>{student.date_created}</td>
                {/* <td>
                  <button className="view-more" onClick={() => openDetailModal(student)}>View More</button>
                </td> */}

<td>
  <Link to={`/students/${student.id}`}>
    <button className="view-more">View More</button>
  </Link>
</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add Student Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Student"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Add Student</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" name="name" value={newStudent.name} onChange={handleChange} required />
          </label>
          <label>
            Contact Number:
            <input type="text" name="contact_number" value={newStudent.contact_number} onChange={handleChange} />
          </label>
          <label>
            Email ID:
            <input type="email" name="email_id" value={newStudent.email_id} onChange={handleChange} />
          </label>
          <label>
            Password:
            <input type="text" name="password" readOnly value={newStudent.password} />
          </label>
          <button type="submit">Add Student</button>
          <button type="button" onClick={closeModal}>Cancel</button>
        </form>
      </Modal>

      {/* Student Detail Modal */}
      <Modal
        isOpen={detailModalIsOpen}
        onRequestClose={closeDetailModal}
        contentLabel="Student Details"
        className="modal"
        overlayClassName="modal-overlay"
      >
        {selectedStudent && (
          <>
            <h2>Student Details</h2>
            <p><strong>Name:</strong> {selectedStudent.name}</p>
            <p><strong>Contact Number:</strong> {selectedStudent.contact_number}</p>
            <p><strong>Email ID:</strong> {selectedStudent.email_id}</p>
            <p><strong>Student ID:</strong> {selectedStudent.student_id}</p>
            <p><strong>IP Address:</strong> {selectedStudent.ip_address}</p>
            <p><strong>Date Created:</strong> {selectedStudent.date_created}</p>
            <p><strong>Courses Enrolled:</strong> {selectedStudent.courses_enrolled}</p>

            <button onClick={handleResetIp}>Reset IP Address</button>
            <button onClick={handleChangePassword}>Change Password</button>
            <button onClick={handleDelete} style={{ backgroundColor: 'red', color: 'white' }}>Delete Student</button>
            <button onClick={openEnrollModal} style={{ backgroundColor: 'blue', color: 'white' }}>Enroll in Course</button>
            <button onClick={openUnenrollModal} style={{ backgroundColor: 'orange', color: 'white' }}>Unenroll from Course</button>
            <button type="button" onClick={closeDetailModal}>Close</button>
          </>
        )}
      </Modal>

      {/* Enroll Modal */}
      <Modal
        isOpen={enrollModalIsOpen}
        onRequestClose={closeEnrollModal}
        contentLabel="Enroll in Course"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Enroll in Course</h2>
        <label>
          Select Course:
          <select value={selectedCourse} onChange={(e) => setSelectedCourse(e.target.value)}>
            <option value="">Select a course</option>
            {courses.map(course => (
              <option key={course.id} value={course.id}>{course.name}</option>
            ))}
          </select>
        </label>
        <button onClick={handleEnroll}>Enroll</button>
        <button onClick={closeEnrollModal}>Cancel</button>
      </Modal>

      {/* Unenroll Modal */}
      {
        selectedStudent &&(
            <Modal
        isOpen={unenrollModalIsOpen}
        onRequestClose={closeUnenrollModal}
        contentLabel="Unenroll from Course"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Unenroll from Course</h2>
        <label>
          Select Course:
          <select value={selectedEnrolledCourse} onChange={(e) => setSelectedEnrolledCourse(e.target.value)}>
            <option value="">Select a course</option>
            {selectedStudent.courses_enrolled.map(course => (
              <option key={course} value={course}>{course}</option>
            ))}
          </select>
        </label>
        <button onClick={handleUnenroll}>Unenroll</button>
        <button onClick={closeUnenrollModal}>Cancel</button>
      </Modal>
        )
      }
    </div>
  );
};

export default StudentScreen;
