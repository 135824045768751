import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import HomeVerticalNavbar from '../../components/VerticalNavBars/HomePage';

const ViewTestScreen = () => {
  const { id } = useParams(); 
  const navigate = useNavigate();
  const [test, setTest] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [showEnrollPopup, setShowEnrollPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const fetchTest = async () => {
    try {
        const response = await axios.get(`https://backend.azad.deloai.com/admin/test/tests/${id}`);
        const formattedTest = {
            ...response.data,
            start_date: formatDate(response.data.start_date),
            end_date: formatDate(response.data.end_date),
        };
        console.log(formattedTest)
        setTest(formattedTest);
    } catch (err) {
        const errorMessage = err.response?.data?.error || 'Error fetching test details';
        setError(errorMessage);
        console.error(err);
    } finally {
        setLoading(false);
    }
};

// Similar modifications can be made for other functions like fetchStudents, handleSave, etc.

  useEffect(() => {
   

    fetchTest();
  }, [id]);

  const fetchStudents = async () => {
    setLoading(true); // Start loading
    try {
        const response = await axios.get('https://backend.azad.deloai.com/admin/student/students');
        setStudents(response.data);
        setFilteredStudents(response.data)
    } catch (err) {
        const errorMessage = err.response?.data?.error || 'Error fetching students';
        setError(errorMessage);
        console.error(err);
    } finally {
        setLoading(false); // Stop loading
    }
};


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const handleChange = (e) => {
    setTest({ ...test, [e.target.name]: e.target.value });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    const filtered = students.filter(student =>
      student.name.toLowerCase().includes(searchQuery) ||
      student.contact_number.toLowerCase().includes(searchQuery) ||
      student.email_id.toLowerCase().includes(searchQuery) ||
      student.student_id.toLowerCase().includes(searchQuery)
    );
    setFilteredStudents(filtered);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const updatedFields = {
        name: test.name,
        start_date: test.start_date,
        start_time: test.start_time,
        duration: test.duration,
        end_date: test.end_date,
        end_time: test.end_time,
      };
      const response = await axios.put(`https://backend.azad.deloai.com/admin/test/tests/${id}`, updatedFields);
      
      if (response.status === 200) {
        const updatedTest = {
          ...response.data,
          start_date: formatDate(response.data.start_date),
          end_date: formatDate(response.data.end_date),
        };
        setTest(updatedTest);
        setIsEditing(false);
        alert('Test updated successfully!');
      } else {
        alert('Failed to update test.');
      }
    } catch (err) {
      setError('Error saving test details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEnrollStudent = async (studentId) => {
    try {
        await axios.post('https://backend.azad.deloai.com/admin/test/enroll_student', { test_id: id, student_id: studentId });
        alert('Student enrolled successfully!');
        setShowEnrollPopup(false);
        fetchTest();
    } catch (err) {
        const errorMessage = err.response?.data?.message || 'Failed to enroll student.';
        alert(errorMessage);
        console.error(err);
    }
};
const handleUnenrollStudent = async (studentId) => {
  try {
      await axios.post('https://backend.azad.deloai.com/admin/test/unenroll_student', { test_id: id, student_id: studentId });
      alert('Student unenrolled successfully!');
      setShowEnrollPopup(false);
      fetchTest();
  } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to unenroll student.';
      alert(errorMessage);
      console.error(err);
  }
};


  const handleViewTestStudent = (studentId) => {
    navigate(`/test-report/${id}/${studentId}`);
  };

  if (loading) return <div style={{ textAlign: 'center', marginTop: '20px' }}>Loading...</div>;
  if (error) return <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>;
  if (!test) return <div style={{ textAlign: 'center' }}>No test found.</div>;

  return (
    <div style={{ padding: '20px', margin: '0 auto', fontFamily: 'Arial, sans-serif', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
      <HomeVerticalNavbar />

      <div className='content'>
      <h2 style={{ textAlign: 'center', color: '#333' }}>Test : {test.name}</h2>

        {/* Test Details Container */}
        <div style={{ 
  margin: '20px auto', 
  maxWidth: '600px', 
  padding: '20px', 
  backgroundColor: '#fff', 
  borderRadius: '8px', 
  boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)', 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center' // Centering children horizontally
}}>      <form onSubmit={handleSave} style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0 auto' }}>
  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
    <label style={{ flex: '1', marginRight: '10px' }}>
      Name:
      <input 
        type="text" 
        name="name" 
        value={test.name} 
        onChange={handleChange} 
        disabled={!isEditing} 
        required 
        style={{ marginLeft: '10px', padding: '8px', maxWidth: '150px', width: '100%', border: '1px solid #ccc', borderRadius: '4px' }}
      />
    </label>
    <label style={{ flex: '1', marginRight: '10px' }}>
      Start Date:
      <input 
        type="date" 
        name="start_date" 
        value={test.start_date} 
        onChange={handleChange} 
        disabled={!isEditing} 
        required 
        style={{ marginLeft: '10px', padding: '8px', maxWidth: '150px', width: '100%', border: '1px solid #ccc', borderRadius: '4px' }}
      />
    </label>
    <label style={{ flex: '1' }}>
      Start Time:
      <input 
        type="time" 
        name="start_time" 
        value={test.start_time} 
        onChange={handleChange} 
        disabled={!isEditing} 
        required 
        style={{ marginLeft: '10px', padding: '8px', maxWidth: '150px', width: '100%', border: '1px solid #ccc', borderRadius: '4px' }}
      />
    </label>
  </div>

  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
    <label style={{ flex: '1', marginRight: '10px' }}>
      End Date:
      <input 
        type="date" 
        name="end_date" 
        value={test.end_date} 
        onChange={handleChange} 
        disabled={!isEditing} 
        required 
        style={{ marginLeft: '10px', padding: '8px', maxWidth: '150px', width: '100%', border: '1px solid #ccc', borderRadius: '4px' }}
      />
    </label>
    <label style={{ flex: '1', marginRight: '10px' }}>
      End Time:
      <input 
        type="time" 
        name="end_time" 
        value={test.end_time} 
        onChange={handleChange} 
        disabled={!isEditing} 
        required 
        style={{ marginLeft: '10px', padding: '8px', maxWidth: '150px', width: '100%', border: '1px solid #ccc', borderRadius: '4px' }}
      />
    </label>
    <label style={{ flex: '1' }}>
      Duration (minutes):
      <input 
        type="number" 
        name="duration" 
        value={test.duration} 
        onChange={handleChange} 
        disabled={!isEditing} 
        required 
        style={{ marginLeft: '10px', padding: '8px', maxWidth: '150px', width: '100%', border: '1px solid #ccc', borderRadius: '4px' }}
      />
    </label>
  </div>
</form>



        <div style={{ margin: '20px 0', display: 'flex', flexDirection: 'column' }}>
          {isEditing ? (
            <>
              <button 
  type="submit" 
  onClick={handleSave} 
  disabled={loading} 
  style={{ width: '600px', padding: '10px', marginBottom: '10px', backgroundColor: '#28a745', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
>
  Save
</button>
<button 
  type="button" 
  onClick={() => setIsEditing(false)} 
  style={{ width: '600px', padding: '10px', backgroundColor: '#dc3545', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
>
  Cancel
</button>

            </>
          ) : (
            <button 
            type="button" 
            onClick={handleEdit} 
            style={{ width: '600px', padding: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
          >
            Edit
          </button>
          
          )}
        </div>
      </div>

      {/* Enrolled Students Container */}
      <div style={{ padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)', marginBottom: '20px' }}>
        <h3 style={{ textAlign: 'center', color: '#333', marginBottom: '20px' }}>Enrolled Students</h3>
      

        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
          <thead>
            <tr>
              <th style={{ borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#f1f1f1' }}>ID</th>
              <th style={{ borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#f1f1f1' }}>Name</th>
              <th style={{ borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#f1f1f1' }}>Phone</th>
              <th style={{ borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#f1f1f1' }}>Email</th>
              <th style={{ borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#f1f1f1' }}>Status</th>

              <th style={{ borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#f1f1f1' }}>Action</th>
            </tr>
          </thead>
          <tbody>
  {test.students_enrolled.map(student => {
    // Check if the student has a result
    const studentResult = test.results.find(result => result.student_id === student.id);
    console.log("Result")
    console.log(studentResult)
    console.log(test.results)
    console.log(student.id)

    return (
      <tr key={student.id} style={{ borderBottom: '1px solid #ddd' }}>
        <td style={{ padding: '10px' }}>{student.student_id}</td>
        <td style={{ padding: '10px' }}>{student.name}</td>
        <td style={{ padding: '10px' }}>{student.contact_number}</td>
        <td style={{ padding: '10px' }}>{student.email_id}</td>
        <td style={{ padding: '10px' }}>
          {studentResult && studentResult.status === 1 ? (
  <button onClick={() => handleViewTestStudent(student.id)} style={{ padding: '5px 10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
View Report </button>
          ) : studentResult && studentResult.status === 0? (
            <span style={{ color: 'red' }}>Student Erroor</span>
          ) :
          <span style={{ color: 'red' }}>Not given Yet</span>
 
          }
        </td>
        <td style={{ padding: '10px' }}>
        <button onClick={() => handleUnenrollStudent(student.id)} style={{ padding: '5px 10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
         Unenroll Student
        </button>
        </td>
      </tr>
    );
  })}
</tbody>

        </table>
        <button
          onClick={() => { setShowEnrollPopup(true); fetchStudents(); }}
          className="enroll-button"
          style={{ width: '100%', padding: '10px', marginTop: '20px', backgroundColor: '#17a2b8', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
        >
          Enroll Student
        </button>
        <button 
          onClick={() => navigate('/tests')} 
          className="back-button" 
          style={{ width: '100%', padding: '10px', marginTop: '20px', backgroundColor: '#6c757d', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
        >
          Back to Tests
        </button>
      </div>

      {showEnrollPopup && (
        <div className="popup" style={{
          position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', width: '60%', maxHeight: '80%', overflowY: 'scroll' }}>
            <h3>Enroll a Student</h3>
            <input
              type="text"
              placeholder="Search students..."
              value={searchQuery}
              onChange={handleSearch}
              style={{ padding: '10px', marginBottom: '10px', width: '100%', border: '1px solid #ccc', borderRadius: '4px' }}
            />
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#f1f1f1' }}>ID</th>
                  <th style={{ borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#f1f1f1' }}>Name</th>
                  <th style={{ borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#f1f1f1' }}>Phone</th>
                  <th style={{ borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#f1f1f1' }}>Email</th>
                  <th style={{ borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#f1f1f1' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents.map(student => (
                  <tr key={student.id} style={{ borderBottom: '1px solid #ddd' }}>
                    <td style={{  }}>{student.student_id}</td>
                    <td style={{  }}>{student.name}</td>
                    <td style={{  }}>{student.contact_number}</td>
                    <td style={{  }}>{student.email_id}</td>
                    <td style={{  }}>
                      <button onClick={() => handleEnrollStudent(student.id)} style={{ padding: '5px 10px', backgroundColor: '#17a2b8', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                        Enroll
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={() => setShowEnrollPopup(false)} style={{ marginTop: '20px', padding: '10px', width: '100%', backgroundColor: '#dc3545', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Close</button>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default ViewTestScreen;
