import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import HomeVerticalNavbar from '../../components/VerticalNavBars/HomePage';
import Modal from 'react-modal';
import { uploadFile } from '../../upload-vedio';

const EditPackageScreen = () => {
  const { package_id } = useParams();
  console.log(package_id);
  const navigate = useNavigate();

  const [packageData, setPackageData] = useState({
    students_enrolled: [], // Initialize with an empty array
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedPackage, setEditedPackage] = useState({
    name: '',
    topics: '',
    professors: '',
    price: '',
    thumbnail_url: ''
  });
  const [thumbnailFile, setThumbnailFile] = useState(null); // New state for file
  const [loading, setLoading] = useState(true); // Loading state

  const fetchPackageData = async () => {
    try {
      const response = await axios.get(`https://backend.azad.deloai.com/admin/package/packages/${package_id}`);
      setPackageData(response.data);
      setEditedPackage({
        name: response.data.name,
        topics: response.data.topics.join(','),
        professors: response.data.professors.join(','),
        price: response.data.price,
        thumbnail_url: response.data.thumbnail_url
      });
      setLoading(false);
    } catch (error) {
      console.error('There was an error fetching the package!', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackageData();
  }, [package_id]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditedPackage({
      name: '',
      topics: '',
      professors: '',
      price: '',
      thumbnail_url: ''
    });
    setThumbnailFile(null);
  };

  const handleChange = (e) => {
    setEditedPackage({ ...editedPackage, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setThumbnailFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let thumbnailUrl = editedPackage.thumbnail_url;

    if (thumbnailFile) {
      try {
        const url = await uploadFile(thumbnailFile, null, 'thumbnails');
        thumbnailUrl = url;
      } catch (error) {
        console.error('There was an error uploading the file!', error);
        setLoading(false);
        return; // Exit if file upload fails
      }
    }

    try {
      await axios.put(`https://backend.azad.deloai.com/admin/package/packages/${package_id}`, {
        ...editedPackage,
        thumbnail_url: thumbnailUrl,
        topics: editedPackage.topics.split(','),
        professors: editedPackage.professors.split(',')
      });

      fetchPackageData(); // Re-fetch the updated package data
      closeModal(); // Close modal after successful update
      alert('Package updated successfully!');
    } catch (error) {
      console.error('There was an error updating the package!', error);
      alert('Failed to update package.');
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  if (!packageData) return <div>Loading package data...</div>;

  return (
    <div className="edit-course-screen">
      <HomeVerticalNavbar />
      <div className="content">
        <div className="top-bar">
          <button className="edit-button" onClick={openModal}>Edit Package</button>
        </div>
        <h2 className='heading2'>Package Detail</h2>
        <div className="course-details" style={{ display: "flex" }}>
          <img src={packageData.thumbnail_url} alt={packageData.name} className="course-thumbnail3" />
          <div className="course-content22" style={{ marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <h3 className="course-title">{packageData.name}</h3>
            <p><strong>Topics:</strong> {packageData.topics.join(', ')}</p>
            <p><strong>Professors:</strong> {packageData.professors.join(', ')}</p>
            <p><strong>Price:</strong> {packageData.price}</p>
          </div>
        </div>
        <h2 className='heading2'>Enrolled students</h2>
        <table className="student-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact Number</th>
              <th>Email ID</th>
              <th>Student ID</th>
              <th>IP Address</th>
              <th>Date Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {packageData.students_enrolled && packageData.students_enrolled.map(student => (
              <tr key={student.id}>
                <td>{student.name}</td>
                <td>{student.contact_number}</td>
                <td>{student.email_id}</td>
                <td>{student.student_id}</td>
                <td>{student.ip_address}</td>
                <td>{student.date_created}</td>
                <td>
                  <Link to={`/students/${student.id}`}>
                    <button className="view-more">View More</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Package"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Edit Package</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" name="name" value={editedPackage.name} onChange={handleChange} required />
          </label>
          <label>
            Topics (comma separated):
            <input type="text" name="topics" value={editedPackage.topics} onChange={handleChange} />
          </label>
          <label>
            Professors (comma separated):
            <input type="text" name="professors" value={editedPackage.professors} onChange={handleChange} />
          </label>
          <label>
            Price:
            <input type="number" name="price" value={editedPackage.price} onChange={handleChange} step="0.01" required />
          </label>
          <label>
            Thumbnail:
            <input type="file" accept="image/*" onChange={handleFileChange} />
          </label>
          <button type="submit">Save Changes</button>
          <button type="button" onClick={closeModal} className="cancel">Cancel</button>
        </form>
      </Modal>
    </div>
  );
};

export default EditPackageScreen;
