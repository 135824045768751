import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import HomeVerticalNavbar from '../../components/VerticalNavBars/HomePage';

const PackageBuilderScreen = () => {
  const { package_id } = useParams();
  const [allCoursesSearch, setAllCoursesSearch] = useState('');
  const [selectedCoursesSearch, setSelectedCoursesSearch] = useState('');
  const [packageData, setPackageData] = useState({ students_enrolled: [], courses: [] });
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const filteredAllCourses = courses.filter(course =>
    course.name.toLowerCase().includes(allCoursesSearch.toLowerCase())
  );
  const filteredSelectedCourses = packageData.courses.filter(course =>
    course.name.toLowerCase().includes(selectedCoursesSearch.toLowerCase())
  );

  useEffect(() => {
    axios.get('https://backend.azad.deloai.com/admin/course/courses')
      .then(response => {
        setCourses(response.data);
        setErrorMessage('');
      })
      .catch(error => {
        setErrorMessage('There was an error fetching the courses!');
        setSuccessMessage('');
      });
  }, []);

  const fetchPackageData = async () => {
    try {
      const response = await axios.get(`https://backend.azad.deloai.com/admin/package/packages/${package_id}`);
      setPackageData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('There was an error fetching the package!', error);
      setLoading(false);
      setErrorMessage('There was an error fetching the package!');
      setSuccessMessage('');
    }
  };

  useEffect(() => {
    fetchPackageData();
  }, [package_id]);

  const handleEnroll = (courseId) => {
    axios.post(`https://backend.azad.deloai.com/admin/package/${packageData.id}/add_course/${courseId}`)
      .then(() => {
        setSuccessMessage('Course Added successfully!');
        setErrorMessage('');
        fetchPackageData();
      })
      .catch(error => {
        setErrorMessage('There was an error adding the course!');
        setSuccessMessage('');
      });
  };

  const handleUnenroll = (courseId) => {
    axios.post(`https://backend.azad.deloai.com/admin/package/${packageData.id}/remove_course/${courseId}`)
      .then(() => {
        setSuccessMessage('Course Removed successfully!');
        setErrorMessage('');
        fetchPackageData();
      })
      .catch(error => {
        setErrorMessage('There was an error removing the course!');
        setSuccessMessage('');
      });
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="edit-course-screen">
      <HomeVerticalNavbar />
      <div className="content">
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}
        <h2 className='heading2'>Package Detail</h2>
        <div className="course-details" style={{ display: "flex" }}>
          <img src={packageData.thumbnail_url} alt={packageData.name} className="course-thumbnail3" />
          <div className="course-content22" style={{ marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <h3 className="course-title">{packageData.name}</h3>
            <p><strong>Topics:</strong> {packageData.topics.join(', ')}</p>
            <p><strong>Professors:</strong> {packageData.professors.join(', ')}</p>
            <p><strong>Price:</strong> {packageData.price}</p>
          </div>
        </div>
        <h2 className='heading2'>Package Courses</h2>
        <input
          type="text"
          placeholder="Search selected courses..."
          value={selectedCoursesSearch}
          onChange={(e) => setSelectedCoursesSearch(e.target.value)}
        />
        <div className="course-cards">
          {filteredSelectedCourses.length > 0 ? (
            filteredSelectedCourses.map(course => (
              <div className="course-card" key={course.id}>
                <img src={course.thumbnail_url} alt={course.name} className="course-thumbnail2" />
                <div className="course-content">
                  <h3 className="course-title">
                    {course.name.length > 100 ? `${course.name.substring(0, 100)}...` : course.name}
                  </h3>
                  <p>Course Code : {course.course_id}</p>
                  <button className="enroll-button" onClick={() => handleUnenroll(course.id)}>
                    Remove Course
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div>No selected courses found</div>
          )}
        </div>
        <h2 className='heading2'>All Courses</h2>
        <input
          type="text"
          placeholder="Search all courses..."
          value={allCoursesSearch}
          onChange={(e) => setAllCoursesSearch(e.target.value)}
        />
        <div className="course-cards">
          {filteredAllCourses.length > 0 ? (
            filteredAllCourses.map(course => (
              <div className="course-card" key={course.id}>
                <img src={course.thumbnail_url} alt={course.name} className="course-thumbnail2" />
                <div className="course-content">
                  <h3 className="course-title">
                    {course.name.length > 100 ? `${course.name.substring(0, 100)}...` : course.name}
                  </h3>
                  <p>Course Code : {course.course_id}</p>
                  <button className="enroll-button" onClick={() => handleEnroll(course.id)}>
                    Add Course
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div>No courses found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackageBuilderScreen;
