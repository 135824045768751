import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import HomeVerticalNavbar from '../../components/VerticalNavBars/HomePage';
import Modal from 'react-modal';
import './EditCourseScreen.css';
import { uploadFile } from '../../upload-vedio';
import { Link } from 'react-router-dom';
const EditCourseScreen = () => {
  const { course_id } = useParams();
  const navigate = useNavigate();

  const [course, setCourse] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedCourse, setEditedCourse] = useState({
    name: '',
    topics: '',
    professors: '',
    price: '',
    whole_duration: '',
    thumbnail_url: ''
  });
  const [thumbnailFile, setThumbnailFile] = useState(null); // New state for file

  useEffect(() => {
    axios.get(`https://backend.azad.deloai.com/admin/course/courses/${course_id}`)
      .then(response => {
        setCourse(response.data);
        setEditedCourse({
          name: response.data.name,
          topics: response.data.topics.join(','),
          professors: response.data.professors.join(','),
          price: response.data.price,
          whole_duration: response.data.whole_duration,
          thumbnail_url: response.data.thumbnail_url
        });
      })
      .catch(error => {
        console.error('There was an error fetching the course!', error);
      });
  }, [course_id]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (e) => {
    setEditedCourse({ ...editedCourse, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setThumbnailFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let thumbnailUrl = editedCourse.thumbnail_url;

    if (thumbnailFile) {
      try {
        const url = await uploadFile(thumbnailFile, null, 'thumbnails');
        thumbnailUrl = url;
      } catch (error) {
        console.error('There was an error uploading the file!', error);
      }
    }

    axios.put(`https://backend.azad.deloai.com/admin/course/courses/${course_id}`, {
      ...editedCourse,
      thumbnail_url: thumbnailUrl,
      topics: editedCourse.topics.split(','),
      professors: editedCourse.professors.split(',')
    })
      .then(response => {
        setCourse(response.data);
        closeModal();
      })
      .catch(error => {
        console.error('There was an error updating the course!', error);
      });
  };

  if (!course) return <div>Loading...</div>;

  return (
    <div className="edit-course-screen">
      <HomeVerticalNavbar />
      <div className="content">
        <div className="top-bar">
          <button className="edit-button" onClick={openModal}>Edit Course</button>
        </div>
<h2 className='heading2'>Course Detail</h2>
        <div className="course-details" style={{display:"flex"}}>

          <img src={course.thumbnail_url} alt={course.name} className="course-thumbnail3" />
          <div className="course-content22" style={{marginLeft: "20px" ,display:"flex" ,flexDirection:"column" ,alignItems:"flex-start"}}>
            <h3 className="course-title">{course.name}</h3>
            <p><strong>Topics:</strong> {course.topics.join(', ')}</p>
            <p><strong>Professors:</strong> {course.professors.join(', ')}</p>
            <p><strong>Price:</strong> {course.price}</p>
          </div>
        </div>
        <h2 className='heading2'>Enrolled students</h2>

        <table className="student-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact Number</th>
              <th>Email ID</th>
              <th>Student ID</th>
              <th>IP Address</th>
              <th>Date Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {course.students_enrolled.map(student => (
              <tr key={student.id}>
                <td>{student.name}</td>
                <td>{student.contact_number}</td>
                <td>{student.email_id}</td>
                <td>{student.student_id}</td>
                <td>{student.ip_address}</td>
                <td>{student.date_created}</td>
                {/* <td>
                  <button className="view-more" onClick={() => openDetailModal(student)}>View More</button>
                </td> */}

<td>
  <Link to={`/students/${student.id}`}>
    <button className="view-more">View More</button>
  </Link>
</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Course"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Edit Course</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" name="name" value={editedCourse.name} onChange={handleChange} required />
          </label>
          <label>
            Topics (comma separated):
            <input type="text" name="topics" value={editedCourse.topics} onChange={handleChange} required />
          </label>
          <label>
            Professors (comma separated):
            <input type="text" name="professors" value={editedCourse.professors} onChange={handleChange}required/>
          </label>
          <label>
            Price:
            <input type="number" name="price" value={editedCourse.price} onChange={handleChange} step="0.01" required />
          </label>
       
          <label>
            Thumbnail:
            <input type="file" accept="image/*" onChange={handleFileChange} />
          </label>
          <button type="submit">Save Changes</button>
          <button type="button" onClick={closeModal} className="cancel">Cancel</button>
        </form>
      </Modal>
    </div>
  );
};

export default EditCourseScreen;
