// VerticalNavbar.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import './VerticalNavbar.css'; // Import the CSS file for styling
import { Link } from 'react-router-dom';
const HomeVerticalNavbar = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="vertical-navbar">
      <ul className="nav-links">
        <h3>Azad Ics Academy</h3>
        <h4>Admin Panel</h4>
        <li><Link to={'/'}>Students</Link></li>
        <li><Link to={'/courses'}>Courses</Link></li>

        <li><Link to={'/packages'}>Packages</Link></li>
        <li><Link to={'/tests'}>Tests</Link></li>
        <li><Link to={'/questions'}>Questions</Link></li>


        {/* Add more links as needed */}
        <li>
          <button onClick={handleLogout} className="logout-button">Logout</button>
        </li>
      </ul>
    </div>
  );
};

export default HomeVerticalNavbar;
